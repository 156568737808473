header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 3rem;
  padding: 1rem;
  background-color: #F0F5FA;

  .log-out__button {
    text-decoration: underline;
    cursor: pointer;
    color: blue;
  }

  span {
    margin-left: 1rem;
  }
}