.li-input {
  input {
    background: #FFFFFF;
    border: 1px solid #CFD8DF;
    box-sizing: border-box;
    border-radius: 8px;
    height: 48px;
    width: 100%;
    padding: 0 0.5rem;

    &::placeholder {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #A7ACB9;
    }
  }

  &__label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #45484D;
    padding-left: 0.2rem;
    text-align: left;
  }

  &__error {
    padding: 0;
    margin: 0;
    color: red;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    text-align: left;
  }

  // Number input type's arrows disappeared
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}