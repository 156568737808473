.statistics-page {
  padding: 2rem;

  .statistics-page__main-block {
    display: flex;
    flex-direction: row;
  }

  &__history-item {
    display: flex;
    height: 2rem;
    background-color: #d9f6f4;
    justify-content: center;
    align-items: center;
    border-radius: 0.4rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
  }

  h2 {
    color: #08c;
  }

  h2, h4 {
    font-weight: 100;
    text-align: center;
  }

  .reset-button {
    background-color: orangered;
    color: white;
  }
}